import { Link } from "react-router-dom";

import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";

import girlWaving from "@/assets/girl-waving.png";
import { useResource } from "@/hooks/resources";
import UserType from "@/types/user-type";

const SignUpCompletePage = () => {
  const [currentUser] = useResource<UserType>("/users/me");

  return (
    <UnauthenticatedPageLayout>
      <div className="border rounded-lg flex justify-between p-4 bg-gray-100 h-[260px]">
        <div className="flex flex-col gap-4">
          <h1 className="text-4xl font-semibold">Membership Confirmation!</h1>

          <p>Thank you for becoming a member of Sing and Speak 4 Kids!</p>

          <p>
            Are you ready to <strong>BEGIN NOW?</strong>
          </p>

          <div className="flex items-center gap-4">
            <a
              href={
                currentUser?.role === "parent"
                  ? "https://singandspeak4kids.com/parents/training-center-for-parents-caregivers"
                  : "https://singandspeak4kids.com/professionals/training-center-for-professionals"
              }
              className="btn-primary w-40"
            >
              Training Videos
            </a>
            <span>OR</span>
            <Link to="/songs" className="btn-primary w-40">
              Start Playing
            </Link>
          </div>

          <p>
            If you have any questions, please send us an email at{" "}
            <a href="mailto:SS4KidsSupport@iqsonics.com" className="link">
              SS4KidsSupport@iqsonics.com
            </a>
          </p>
        </div>

        <img
          src={girlWaving}
          alt="A girl waving her hand"
          className="hidden md:block h-full object-contain"
        />
      </div>
    </UnauthenticatedPageLayout>
  );
};

export default SignUpCompletePage;
