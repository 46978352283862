import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { RadioInput } from "@/components/inputs/radio-input";
import { SelectInput } from "@/components/inputs/select-input";
import { TextInput } from "@/components/inputs/text-input";
import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { BooleanInput } from "@/components/inputs/boolean-input";
import { request } from "@/utils/request";
import { customErrorMap } from "@/utils/custom-error-map";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { useSetToken } from "@/hooks/authentication";

const formSchema = z.object({
  role: z.literal("parent"),
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  email: z.string().email().nonempty(),
  phoneNumber: z.string().nonempty(),
  smsContented: z.boolean(),
  password: z.string().min(8),
  guardianConfirmation: z.literal(true),
  acceptTerms: z.literal(true),
  locale: z.enum(["en", "es"]),
  heardAboutUsFrom: z.enum([
    "facebook",
    "email",
    "website",
    "therapist_clinic",
    "teacher_school",
    "friends_family",
    "other",
  ]),
  childrenAttributes: z
    .array(
      z.object({
        firstName: z.string().nonempty(),
        lastName: z.string().nonempty(),
        gender: z.enum(["male", "female"]),
        ageRange: z.enum(["two_to_three", "four_to_five", "six_or_older"]),
      }),
    )
    .nonempty(),
});

const SignUpParentsPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: {
      role: "parent",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      smsContented: "",
      password: "",
      guardianConfirmation: false,
      acceptTerms: false,
      subcribeToNewsletter: false,
      locale: "",
      heardAboutUsFrom: "",
      childrenAttributes: [
        {
          firstName: "",
          lastName: "",
          gender: "",
          ageRange: "",
        },
      ],
    },
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "childrenAttributes",
  });

  const setToken = useSetToken();

  const onSubmit = async (data: FieldValues) => {
    const response = await request.post<{ token: string; redirectUrl: string }>(
      "/users/registrations",
      data,
    );

    if (response.errors) {
      for (const [field, errors] of Object.entries<Array<string>>(
        response.errors,
      )) {
        setError(field, { type: "manual", message: errors.join(", ") });
      }
    }

    if (response.ok && response.data?.redirectUrl) {
      await setToken(response.data?.token);

      window.location.href = response.data.redirectUrl;
    }
  };

  const heardAboutUsFrom = watch("heardAboutUsFrom");

  const isTabletOrDesktop = useBreakpoint("sm");

  return (
    <UnauthenticatedPageLayout
      title="Parent / Caregiver Registration"
      className="flex flex-col gap-4 items-center"
    >
      <p>
        Required Fields{" "}
        <span
          className="text-red-600 underline decoration-dotted cursor-help"
          title="required"
        >
          *
        </span>
      </p>

      <div className="font-semibold text-red-600 text-center">
        <h2 className="text-2xl">IMPORTANT</h2>
        <p className="text-lg">
          For families who are being sponsored, you must have your coupon code
          before proceeding.
        </p>
        <p>(Example: CA Regional Centers)</p>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 w-full"
      >
        <input type="hidden" {...register("role")} />

        <div className="flex flex-col gap-4 w-full border sm:px-12 p-4 bg-gray-50">
          <TextInput
            name="firstName"
            label="First Name"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="lastName"
            label="Last Name"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="email"
            label="Email"
            hint="Note: This email will be your login and to receive email notifications."
            placeholder="Eg: ss4k@iqsonics.com"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="phoneNumber"
            label="Phone"
            placeholder="Eg: 222-333-4444"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <SelectInput
            name="smsContented"
            label="May we send you occasional text messages to this number?"
            hint="Data charges may apply."
            prompt="Select One..."
            control={control}
            required
            horizontal={isTabletOrDesktop}
            transformValue={(value) => value === "true"}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </SelectInput>

          <TextInput
            name="password"
            type="password"
            label="Password"
            placeholder="Create your password"
            hint="Must be at least 8 characters long."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <hr />

          <BooleanInput
            name="guardianConfirmation"
            label="I am the parent or person legally responsible for the children under my account."
            control={control}
            required
          />

          <BooleanInput
            name="acceptTerms"
            label={
              <>
                I agree to the{" "}
                <a
                  href="https://singandspeak4kids.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://singandspeak4kids.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            control={control}
            required
          />

          <BooleanInput
            name="subcribeToNewsletter"
            label="Subscribe to our newsletter."
            control={control}
          />

          <hr />

          <RadioInput
            name="locale"
            label="What is your preferred language?"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          >
            <option value="en">English</option>
            <option value="es">Spanish / Español</option>
          </RadioInput>

          <SelectInput
            name="heardAboutUsFrom"
            label="Where did you hear about us?"
            prompt="Select One..."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          >
            <option value="facebook">Facebook</option>
            <option value="email">Email</option>
            <option value="website">Website</option>
            <option value="therapist_clinic">Therapist / Clinic</option>
            <option value="teacher_school">Teacher / School</option>
            <option value="friends_family">Friends / Family</option>
            <option value="other">Other</option>
          </SelectInput>

          {heardAboutUsFrom === "other" && (
            <TextInput
              name="heardAboutUsFromOther"
              label="Other"
              control={control}
              required
              horizontal={isTabletOrDesktop}
            />
          )}
        </div>

        <div className="flex flex-col gap-4 w-full border sm:px-12 p-4 bg-gray-50">
          <p>
            You must be the parent or legally responsible person for the
            children under your account.
          </p>

          {fields.map((field, index) => (
            <div
              key={field.id}
              className="border border-primary-blue-500 rounded-lg overflow-clip"
            >
              <div className="flex gap-4 items-center justify-between px-4 py-2 bg-primary-blue-500 h-16">
                <h3 className="text-white">Child {index + 1}</h3>

                {fields.length > 1 && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    className="btn-default text-white hover:text-[unset]"
                  >
                    Remove
                  </button>
                )}
              </div>

              <div className="p-4 grid sm:grid-cols-2 gap-4">
                <TextInput
                  name={`childrenAttributes.${index}.firstName`}
                  label="First Name"
                  control={control}
                  required
                />

                <TextInput
                  name={`childrenAttributes.${index}.lastName`}
                  label="Last Name"
                  control={control}
                  required
                />

                <SelectInput
                  name={`childrenAttributes.${index}.gender`}
                  label="Gender"
                  prompt="Select gender"
                  control={control}
                  required
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </SelectInput>

                <SelectInput
                  name={`childrenAttributes.${index}.ageRange`}
                  label="Age Range"
                  prompt="Select age range"
                  control={control}
                  required
                >
                  <option value="two_to_three">2 - 3 years</option>
                  <option value="four_to_five">4 - 5 years</option>
                  <option value="six_or_older">6 years or older</option>
                </SelectInput>
              </div>
            </div>
          ))}

          <div className="w-full flex justify-center">
            <button
              type="button"
              onClick={() =>
                append({
                  firstName: "",
                  lastName: "",
                  gender: "",
                  ageRange: "",
                })
              }
              className="btn-primary w-[200px]"
            >
              Add Another Child
            </button>
          </div>
        </div>

        <div className="w-full flex justify-center py-8">
          <button type="submit" className="btn-secondary w-[240px] py-4">
            {isSubmitting ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              "Proceed to Checkout"
            )}
          </button>
        </div>
      </form>
    </UnauthenticatedPageLayout>
  );
};

export default SignUpParentsPage;
