import { Link } from "react-router-dom";

import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";

import ss4kKids from "@/assets/ss4k-kids.png";

const SignUpSelectPage = () => {
  return (
    <UnauthenticatedPageLayout
      title="Select Your Membership"
      className="flex flex-col gap-4"
    >
      <div className="grid sm:grid-cols-3 gap-4 my-8">
        <Link
          to="/sign-up/parents"
          className="btn-tertiary text-xl hover:text-white no-underline rounded-lg h-20"
        >
          Parent / Caregiver
          <br />
          Non CA Regional Center
        </Link>

        <Link
          to="/sign-up/regional-center-parents"
          className="btn-secondary text-xl hover:text-white no-underline rounded-lg h-20"
        >
          Parent / Caregiver
          <br />
          CA Regional Center
        </Link>

        <Link
          to="/sign-up/professionals"
          className="btn-primary text-xl hover:text-white no-underline rounded-lg h-20"
        >
          Professional
        </Link>
      </div>

      <p>
        Schools and Clinics who wish to use our program with several children,
        please contact us at{" "}
        <a href="mailto:SS4Kids@iqsonics.com" className="link">
          SS4Kids@iqsonics.com
        </a>{" "}
        and we&apos;ll be happy to get you started.
      </p>

      <p>
        Join our online evidenced-based music language program that supports
        young children with delayed speech development. Sing and Speak 4 Kids is
        a fun, interactive game for kids that offers simple integration for
        therapy and learning sessions, or just for family quality time together!
        Parents and professionals are encouraged to play our game with their
        child.
      </p>

      <p>
        Already have a membership?{" "}
        <Link to="/" className="link">
          Sign In
        </Link>
      </p>

      <div className="flex items-center justify-center border-4 border-orange-500 rounded-3xl p-4">
        <img src={ss4kKids} className="h-[140px]" />
      </div>
    </UnauthenticatedPageLayout>
  );
};

export default SignUpSelectPage;
