import { ReactNode } from "react";
import { Control, FieldValues, useController } from "react-hook-form";

type BooleanInputProps = {
  name: string;
  label: ReactNode;
  hint?: string;
  control: Control<FieldValues>;
  required?: boolean;
};

export const BooleanInput = ({
  name,
  label,
  hint,
  control,
  required = false,
}: BooleanInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <label htmlFor={name} className="flex gap-2">
      <div className="flex items-center h-6">
        <input type="checkbox" {...field} id={name} />
      </div>

      <div className="grow flex flex-col gap-1">
        <div>
          {label}{" "}
          {required && (
            <span
              className="text-red-600 underline decoration-dotted cursor-help"
              title="required"
            >
              *
            </span>
          )}
        </div>
        {hint && <p className="text-xs text-gray-600">{hint}</p>}
        {error && <p className="text-sm text-red-600">{error.message}</p>}
      </div>
    </label>
  );
};
