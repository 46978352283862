import { Link } from "react-router-dom";

import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";

import ss4kKids from "@/assets/ss4k-kids.png";

const SignUpRegionalCenterParentsPage = () => {
  return (
    <UnauthenticatedPageLayout
      title="Welcome to Sing and Speak 4 Kids!"
      className="flex flex-col gap-4"
    >
      <p>
        We are excited that you are interested in using our game. Before you can
        register, we need to receive from your Regional Center the Authorization
        to Purchase Services (APS) form. This will allow us to send you a coupon
        code to register you for free to use our game. Please reach out to your
        caseworker or service coordinator and ask them to send us your APS.
      </p>

      <p>
        Meanwhile, you may visit our website{" "}
        <a
          href="https://SingandSpeak4Kids.com"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          SingandSpeak4Kids.com
        </a>{" "}
        to learn more about our program and{" "}
        <a
          href="https://singandspeak4kids.com/california-regional-centers"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          review our training videos and resources
        </a>{" "}
        -- but please <strong>do NOT register</strong> without the coupon code,
        as you would be charged with your credit card. With the coupon code you
        will receive our game for free.
      </p>

      <p>
        If you have already received a coupon code from us and are ready to
        register, please{" "}
        <Link to="/sign-up/parents" className="link">
          click this link
        </Link>{" "}
        to move forward in the registration process.
      </p>

      <p>
        Still have questions? Please contact us at{" "}
        <a href="mailto:SS4Kids@iqsonics.com" className="link">
          SS4Kids@iqsonics.com
        </a>
        .
      </p>

      <div className="flex items-center justify-center border-4 border-orange-500 rounded-3xl p-4">
        <img src={ss4kKids} className="h-[140px]" />
      </div>
    </UnauthenticatedPageLayout>
  );
};

export default SignUpRegionalCenterParentsPage;
