import { FieldValues, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { customErrorMap } from "@/utils/custom-error-map";
import { TextInput } from "@/components/inputs/text-input";
import { request } from "@/utils/request";
import { SelectInput } from "@/components/inputs/select-input";
import { BooleanInput } from "@/components/inputs/boolean-input";
import { RadioInput } from "@/components/inputs/radio-input";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { useSetToken } from "@/hooks/authentication";

const formSchema = z.object({
  role: z.literal("professional"),
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  email: z.string().email().nonempty(),
  phoneNumber: z.string().nonempty(),
  smsContented: z.boolean(),
  password: z.string().min(8),
  occupation: z.string().nonempty(),
  institution: z.string().nonempty(),
  professionalTitle: z.string().nonempty(),
  acceptTerms: z.literal(true),
  locale: z.enum(["en", "es"]),
  heardAboutUsFrom: z.enum([
    "facebook",
    "email",
    "website",
    "therapist_clinic",
    "teacher_school",
    "friends_family",
    "other",
  ]),
});

const SignUpProfessionalsPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: {
      role: "professional",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      smsContented: "",
      password: "",
      occupation: "",
      occupationOther: "",
      institution: "",
      professionalTitle: "",
      website: "",
      acceptTerms: false,
      subcribeToNewsletter: false,
      locale: "",
      heardAboutUsFrom: "",
    },
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  const setToken = useSetToken();

  const onSubmit = async (data: FieldValues) => {
    const response = await request.post<{ token: string; redirectUrl: string }>(
      "/users/registrations",
      data,
    );

    if (response.errors) {
      for (const [field, errors] of Object.entries<Array<string>>(
        response.errors,
      )) {
        setError(field, { type: "manual", message: errors.join(", ") });
      }
    }

    if (response.ok && response.data?.redirectUrl) {
      await setToken(response.data?.token);

      window.location.href = response.data.redirectUrl;
    }
  };

  const heardAboutUsFrom = watch("heardAboutUsFrom");
  const occupation = watch("occupation");

  const isTabletOrDesktop = useBreakpoint("sm");

  return (
    <UnauthenticatedPageLayout
      title="Professional Registration"
      className="flex flex-col gap-4 items-center"
    >
      <p>
        Required Fields{" "}
        <span
          className="text-red-600 underline decoration-dotted cursor-help"
          title="required"
        >
          *
        </span>
      </p>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 w-full"
      >
        <input type="hidden" {...register("role")} />

        <div className="flex flex-col gap-4 w-full border sm:px-12 p-4 bg-gray-50">
          <TextInput
            name="firstName"
            label="First Name"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="lastName"
            label="Last Name"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="email"
            label="Email"
            hint="Note: This email will be your login and to receive email notifications."
            placeholder="Eg: ss4k@iqsonics.com"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="phoneNumber"
            label="Phone"
            placeholder="Eg: 222-333-4444"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <SelectInput
            name="smsContented"
            label="May we send you occasional text messages to this number?"
            hint="Data charges may apply."
            prompt="Select One..."
            control={control}
            required
            horizontal={isTabletOrDesktop}
            transformValue={(value) => value === "true"}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </SelectInput>

          <TextInput
            name="password"
            type="password"
            label="Password"
            placeholder="Create your password"
            hint="Must be at least 8 characters long."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <hr />

          <SelectInput
            name="occupation"
            label="Occupation"
            prompt="Select an Occupation..."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          >
            <option value="speech_language_therapist">
              Speech-Language Therapist
            </option>

            <option value="behaviour_interventionist">
              Behaviour Interventionist
            </option>

            <option value="music_therapist">Music Therapist</option>
            <option value="teacher">Teacher</option>
            <option value="other">Other</option>
          </SelectInput>

          {occupation === "other" && (
            <TextInput
              name="occupationOther"
              label="Other"
              control={control}
              required
              horizontal={isTabletOrDesktop}
            />
          )}

          <TextInput
            name="institution"
            label="Institution"
            placeholder="Enter the name of your place of employment"
            hint="Tip: You may also enter self-employed or retired."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="professionalTitle"
            label="Professional Title"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          />

          <TextInput
            name="website"
            label="Website"
            control={control}
            horizontal={isTabletOrDesktop}
          />

          <hr />

          <BooleanInput
            name="acceptTerms"
            label={
              <>
                I agree to the{" "}
                <a
                  href="https://singandspeak4kids.com/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://singandspeak4kids.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Privacy Policy
                </a>
                .
              </>
            }
            control={control}
            required
          />

          <BooleanInput
            name="subcribeToNewsletter"
            label="Subscribe to our newsletter."
            control={control}
          />

          <hr />

          <RadioInput
            name="locale"
            label="What is your preferred language?"
            control={control}
            required
            horizontal={isTabletOrDesktop}
          >
            <option value="en">English</option>
            <option value="es">Spanish / Español</option>
          </RadioInput>

          <SelectInput
            name="heardAboutUsFrom"
            label="Where did you hear about us?"
            prompt="Select One..."
            control={control}
            required
            horizontal={isTabletOrDesktop}
          >
            <option value="facebook">Facebook</option>
            <option value="email">Email</option>
            <option value="website">Website</option>
            <option value="therapist_clinic">Therapist / Clinic</option>
            <option value="teacher_school">Teacher / School</option>
            <option value="friends_family">Friends / Family</option>
            <option value="other">Other</option>
          </SelectInput>

          {heardAboutUsFrom === "other" && (
            <TextInput
              name="heardAboutUsFromOther"
              label="Other"
              control={control}
              required
              horizontal={isTabletOrDesktop}
            />
          )}
        </div>

        <div className="w-full flex justify-center py-8">
          <button type="submit" className="btn-secondary w-[240px] py-4">
            {isSubmitting ? (
              <FontAwesomeIcon icon="circle-notch" spin />
            ) : (
              "Proceed to Checkout"
            )}
          </button>
        </div>
      </form>
    </UnauthenticatedPageLayout>
  );
};

export default SignUpProfessionalsPage;
