import clsx from "clsx";
import { PropsWithChildren } from "react";
import { Control, FieldValues, useController } from "react-hook-form";

type SelectInputProps = {
  name: string;
  label: string;
  hint?: string;
  prompt?: string;
  control: Control<FieldValues>;
  required?: boolean;
  horizontal?: boolean;
  className?: string;
  transformValue?: (value: string) => string | number | boolean;
};

export const SelectInput = ({
  name,
  label,
  hint,
  prompt,
  control,
  required = false,
  horizontal = false,
  className,
  transformValue,
  children,
}: PropsWithChildren<SelectInputProps>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div
      className={clsx("w-full flex gap-x-4 gap-y-2", {
        "flex-col": !horizontal,
      })}
    >
      <div
        className={clsx({
          "w-1/3 flex items-center justify-end h-11": horizontal,
        })}
      >
        <label htmlFor={name} className="text-right">
          {label}{" "}
          {required && (
            <span
              className="text-red-600 underline decoration-dotted cursor-help"
              title="required"
            >
              *
            </span>
          )}
        </label>
      </div>

      <div className="grow flex flex-col gap-1">
        <select
          {...field}
          id={name}
          className={clsx("rounded w-full", {
            "border-red-500": error,
            "max-w-96": horizontal,
            className,
          })}
          onChange={(event) => {
            return transformValue && event.target.value !== ""
              ? field.onChange(transformValue(event.target.value))
              : field.onChange(event.target.value);
          }}
        >
          {prompt && <option value="">{prompt}</option>}
          {children}
        </select>
        {hint && <p className="text-xs text-gray-600">{hint}</p>}
        {error && <p className="text-sm text-red-600">{error.message}</p>}
      </div>
    </div>
  );
};
